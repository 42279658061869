<template>
  <div class="images">
    <transition name="menu-popover">
      <div v-if="lightboxImage" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <h2 class="downloadImage">
          <a :href="lightboxImage" target="_blank" rel="noopener noreferrer">
            <i class="fa fa-download" aria-hidden="true"></i>
          </a>
        </h2>
        <img :src="lightboxImage" @click="closeLightbox()" />
      </div>
    </transition>

    <transition name="menu-popover">
      <div v-if="lightboxVideo" class="lightbox" @click="closeLightbox()">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <div class="lightbox-vid" v-html="lightboxVideo"></div>
        <!-- <img :src="lightboxImage" @click="closeLightbox()"/> -->
      </div>
    </transition>

    <section
      data-v-5a90ec03
      class="py-5 bg8 bs-shadow mobile-top"
    >
      <div data-v-5a90ec03 class="container">
        <input
          class="top-search bs-large"
          type="text"
          placeholder="Search for media..."
          v-model="searchValue"
          @keyup.enter="searchQuery"
        />
      </div>
    </section>

    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-12">
          <div class="card-block bs-shadow" style="margin-top: 15px">
            <div @click="displayFiles" class="d-flex droplink">
              <h5>Files</h5>
              <span class="material-icons mlauto"> filter_list </span>
            </div>
            <ul v-if="showFiles" class="flexwrap">
              <li v-for="all in allMedia" :key="all.title" class="mktBtns">
                <button
                  class="btn-primary"
                  :class="{
                    active: fileType === 'PDF}%20or%20{JPEG}%20or%20{MPEG4',
                  }"
                  @click="changeFile(all.search)"
                >
                  {{ all.title }}
                </button>
              </li>

              <li v-for="file in fileTypes" :key="file.title" class="mktBtns">
                <button
                  class="btn-primary"
                  :class="{ active: file.search == fileType }"
                  @click="changeFile(file.search)"
                >
                  {{ file.title }}
                </button>
              </li>
            </ul>
          </div>

          <div class="card-block bs-shadow" style="margin-top: 15px">
            <div @click="displayMarkets" class="d-flex droplink">
              <h5>Markets</h5>
              <span class="material-icons mlauto"> filter_list </span>
            </div>
            <ul v-if="showMarkets" class="flexwrap">
              <li v-for="market in markets" :key="market.title" class="mktBtns">
                <button
                  class="btn-primary"
                  :class="{ active: market.search == search }"
                  @click="changeMarket(market.search)"
                >
                  {{ market.title }}
                </button>
              </li>
            </ul>
          </div>

          <div class="card-block bs-shadow">
            <div @click="displayProducts" class="d-flex droplink">
              <h5>Products</h5>
              <span class="material-icons mlauto"> filter_list </span>
            </div>
            <ul v-if="showProducts" class="flexwrap">
              <li
                v-for="product in products"
                :key="product.title"
                class="mktBtns"
              >
                <button
                  class="btn-primary"
                  :class="{ active: product.search == search }"
                  @click="changeMarket(product.search)"
                >
                  {{ product.title }}
                </button>

                <!--
            <button @click="changeMarket(product.search)" class="btn-primary">{{product.title}}</button>
                -->
                <!--
            <button @click="searchCat(product.search)" class="btn-primary">{{product.title}}</button>
                -->
              </li>
            </ul>
          </div>
        </div>

        <div v-if="loading == true" class="col-xs-12 col-sm-12 col-12 col-lg-9">
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#575757'"
              />

              <p>Loading......</p>
            </div>
          </div>
        </div>

        <div
          v-else-if="loading == false && !media.length"
          class="col-xs-12 col-sm-12 col-12 col-lg-9 flexVHcenter"
        >
          <div>
            <img src="@/assets/img/sad.svg" />
            <p>Sorry, nothing was found...</p>
          </div>
        </div>

        <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-9">
          <div
            class="banner d-flex align-items-center p-3 my-3 rounded bs-shadow"
          >
            <div class="d-flex number-showing">
              <p class="mb0">{{ this.media.length + " Results" }}</p>
            </div>

            <div>
              <div class="nav-btns">
                <button v-if="this.offsetVal == 0" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>
                <button v-else @click="offsetValMinus()">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-if="this.media.length < 99" disabled>
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
                <button v-else @click="offsetValPlus()">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>

          <div class="row">
            <div
              v-for="el in media"
              :key="el.data.external_id"
              class="col-md-6 col col-sm-6 col-xs-12 col-12"
            >
              <div class="card bs-shadow br4">
                <!-- pdf image -->
                <!--
                <div v-if="el.data.file_properties['format_type'] == 'pdf'" class="img-responsive-4by3">
                -->
                <div
                  class="img-responsive-4by3"
                  v-bind:style="{
                    backgroundImage:
                      'url(' + el.data.thumbnails['600px'].url + ')',
                  }"
                  style="background-size: cover"
                >
                  <span class="file-icon">
                    <i
                      v-if="el.data.file_properties['format_type'] == 'pdf'"
                      class="fas fa-file-pdf"
                    ></i>
                    <i
                      v-if="el.data.file_properties['format_type'] == 'image'"
                      class="far fa-image"
                    ></i>
                    <i
                      v-if="el.data.file_properties['format_type'] == 'video'"
                      class="fas fa-video"
                    ></i>
                    <i
                      v-else-if="
                        el.data.file_properties['format_type'] == 'office'
                      "
                      class="fas fa-file"
                    ></i>
                  </span>
                  <!--
									<img :src="el.data.thumbnails['600px'].url" />
									-->
                </div>

                <!-- a photo -->
                <!--
                <div
                  v-if="el.data.file_properties['format_type'] == 'image'"
                  class="img-responsive-4by3"
                >
                  <span class="file-icon">
                    <i class="far fa-image"></i>
                  </span>
                  <img :src="el.data.thumbnails['600px'].url" />
                </div>
                -->
                <!--
                <div
                  v-if="el.data.file_properties['format_type'] == 'image'"
                  class="docwrap"
                  :style="{ backgroundImage: 'url(' + el.data.thumbnails['600px'].url + ')' }"
                >
                  <span class="file-icon">
                    <i class="far fa-image"></i>
                  </span>
                </div>
                -->

                <!-- a video -->
                <!--
                <div
                  v-if="el.data.file_properties['format_type'] == 'video'"
                  class="img-responsive-16by9"
                >
                  <span class="file-icon">
                    <i class="fas fa-video"></i>
                  </span>
                  <img :src="el.data.thumbnails['600px'].url" />
                </div>
                -->

                <div class="card-name">
                  <p class="word-wrap">{{ el.data.filename }}</p>

                  <!-- if it's an image -->
                  <span
                    class="button-wrap"
                    v-if="el.data.file_properties['format_type'] == 'image'"
                  >
                    <a
                      class="first links"
                      v-on:click="showLightbox(el.data.thumbnails['600px'].url)"
                    >
                      <i
                        class="material-icons"
                        data-toogle="tooltip"
                        title="Preview this image"
                        >visibility</i
                      >
                    </a>

                    <a
                      class="links mr5"
                      v-clipboard="el.data.embeds['original'].share"
                      v-on:click="copyLink"
                    >
                      <i class="material-icons">file_copy</i>
                    </a>

                    <a
                      class="links mr5"
                      :href="`mailto:?subject=&body=${el.data.embeds['original'].share}`"
                    >
                      <i class="material-icons">send</i>
                    </a>

                    <a
                      class="links"
                      :href="el.data.embeds['original'].url"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        data-v-a2d59bc8
                        aria-hidden="true"
                        class="fa fa-download"
                        data-toogle="tooltip"
                        title="Download this image"
                      ></i>
                    </a>
                  </span>
                  <!-- if it's a document -->

                  <span
                    class="button-wrap"
                    v-if="
                      (el.data.file_properties['format_type'] == 'pdf') |
                        (el.data.file_properties['format_type'] == 'office')
                    "
                  >
                    <a
                      :href="
                        el.data.embeds['document_viewer_with_download'].url
                      "
                      target="_blank"
                      class="first links"
                    >
                      <i
                        class="material-icons"
                        data-toogle="tooltip"
                        title="Preview this PDF"
                        >visibility</i
                      >
                    </a>

                    <a
                      class="links mr5"
                      v-clipboard="el.data.embeds['original'].share"
                      v-on:click="copyLink"
                    >
                      <i class="material-icons">file_copy</i>
                    </a>

                    <a
                      class="links mr5"
                      :href="`mailto:?subject=&body=${el.data.embeds['original'].share}`"
                    >
                      <i class="material-icons">send</i>
                    </a>

                    <a
                      class="links"
                      :href="el.data.embeds['original'].share"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        data-v-a2d59bc8
                        aria-hidden="true"
                        class="fa fa-download"
                        data-toogle="tooltip"
                        title="Download this document"
                      ></i>
                    </a>
                  </span>

                  <!-- It's a video -->
                  <span
                    class="button-wrap"
                    v-if="el.data.file_properties['format_type'] == 'video'"
                  >
                    <a
                      class="first links"
                      v-on:click="
                        showLightboxVideo(el.data.embeds['video_player'].html)
                      "
                    >
                      <i
                        class="material-icons"
                        data-toogle="tooltip"
                        title="Preview this video"
                        >visibility</i
                      >
                    </a>

                    <a
                      class="links mr5"
                      v-clipboard="el.data.embeds['video_player'].url"
                      v-on:click="copyLink"
                    >
                      <i class="material-icons">file_copy</i>
                    </a>

                    <a
                      class="links mr5"
                      :href="`mailto:?subject=&body=${el.data.embeds['video_player'].url}`"
                    >
                      <i class="material-icons">send</i>
                    </a>
                  </span>
                  <!-- end video controls -->
                </div>
              </div>
              <!-- card -->
            </div>
          </div>
          <!-- bottom pagination -->
          <div
            v-if="media.length >= 4"
            class="banner d-flex align-items-center p-3 mb1r rounded bs-shadow"
          >
            <div class="d-flex number-showing">
              <p class="mb0">{{ this.media.length + " Results" }}</p>
            </div>

            <div>
              <div class="nav-btns">
                <button v-if="this.offsetVal == 0" disabled>
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>
                <button v-else @click="offsetValMinus()">
                  <i class="material-icons">keyboard_arrow_left</i> Back
                </button>

                <button v-if="this.media.length < 100" disabled>
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
                <button v-else @click="offsetValPlus()">
                  Next
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
          </div>
          <!-- end bottom pagination -->
        </div>
        <!-- end other column -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";

//old auth with admin priveleges
//headers: {Authorization:'Bearer spacesaver/ab3f641f910eb693804bc5b097a5bda6'}

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer thisisanexampleofunauthorized"
  },
};

export default {
  name: "FavMedia",
  components: {
    AtomSpinner,
  },
  data() {
    return {
      server: process.env.VUE_APP_SERVER_URL,
      pageSlug: "",
      msg: "",
      offsetVal: 0,
      search: "",
      searchValue: "",
      media: [],
      loading: true,
      imagesInPresentation: [],
      selectedImage: "",
      selectedVid: null,
      fileType:
        "PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)",
      lightboxImage: "",
      lightboxVideo: "",
      allMedia: [
        {
          title: "All Media",
          search:
            "PDF}%20or%20{JPEG}%20or%20{MPEG4}%20or%20{Microsoft Excel (xls)} or {Microsoft Word (doc)} or {Microsoft Powerpoint (ppt)} or {Microsoft Excel (xlsx)} or {Microsoft Word (docx)} or {Microsoft Powerpoint (pptx)",
          active: false,
        },
      ],
      images: [
        { id: 1, name: "http://www.google.com/image1" },
        { id: 2, name: "ttp://www.google.com/image2" },
        { id: 3, name: "ttp://www.google.com/image3" },
      ],
      showFiles: true,
      showMarkets: false,
      showProducts: false,
    };
  },
  methods: {
    firstLoad: function () {
      this.loading = true;
      this.$store.commit("setActivePage", "media");
      return axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/v1/fav-media`, auth)
        .then((response) => {
          console.log(response);
          /*
                var i;
                for (i = 0; i < response.data.media.length; i++) {
                    JSON.parse([i].data);
                }
                */
          this.media = response.data.media;
          this.loading = false;
        });
    },

    copyLink: function () {
      this.$noty.success("Successfully copied link to clipboard!");
    },

    copyData(link) {
      this.makeData();
      this.$clipboard(this.invite_code);
      alert("Copied to clipboard");
    },

    addImageToPresentation(name) {
      console.log("adding image to presentation");
      let image = {
        thumb: name,
        large: "large image",
      };
      this.$store.commit("addToImages", image);
      this.$noty.success("Successfully added image to presentation!");
      //this.$noty.error("Oooops! Something went wrong");
    },

    offsetValMinus() {
      this.offsetVal -= 100;
      this.loading = true;

      if (this.searchValue) {
        console.log("search value is set");
        return axios
          .get(
            `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}&limit=99&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`,
            auth
          )
          .then((response) => {
            this.media = response.data.items;
            this.loading = false;
          });
      } else {
        return axios.get(this.endPointCompute, auth).then((response) => {
          this.media = response.data.items;
          this.loading = false;
        });
      }
    },

    offsetValPlus() {
      this.offsetVal += 100;
      this.loading = true;

      if (this.searchValue) {
        console.log("search value is set");
        return axios
          .get(
            `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}&limit=99&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`,
            auth
          )
          .then((response) => {
            this.media = response.data.items;
            this.loading = false;
          });
      } else {
        return axios.get(this.endPointCompute, auth).then((response) => {
          this.media = response.data.items;
          this.loading = false;
        });
      }
    },

    changeMarket(name) {
      this.offsetVal = 0;
      this.loading = true;
      this.searchValue = "";
      //this.$store.commit('changeMarket', name);
      console.log(name);
      this.search = name;

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });

      //axios.get(this.endPointCompute, auth).then(response => (this.media = response.data.items));
      //this.loading = false;
    },

    changeFile(name) {
      this.offsetVal = 0;
      this.loading = true;
      this.fileType = name;

      return axios.get(this.endPointCompute, auth).then((response) => {
        this.media = response.data.items;
        this.loading = false;
      });

      //axios.get(this.endPointCompute, auth).then(response => (this.media = response.data.items));
      //this.loading = false;
    },

    searchQuery(search) {
      this.search = "";
      this.offsetVal = 0;
      this.loading = true;
      return (
        axios
          /*
        .get(
          `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}&limit=100&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`,
          auth
        )
        */
          .get(
            `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20${this.searchValue}&limit=99&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`,
            auth
          )
          .then((response) => {
            this.media = response.data.items;
            this.loading = false;
          })
      );
    },

    showLightbox(media) {
      console.log(media);
      this.lightboxImage = media;
    },
    showLightboxVideo(media) {
      console.log(media);
      this.lightboxVideo = media;
    },

    closeLightbox() {
      this.lightboxImage = "";
      this.lightboxVideo = "";
    },

    isActiveButton: function (text) {
      return text;
    },

    displayFiles() {
      this.showFiles = !this.showFiles;
    },
    displayMarkets() {
      this.showMarkets = !this.showMarkets;
    },
    displayProducts() {
      this.showProducts = !this.showProducts;
    },
    /*
    showCats() {
      // https://api.widencollective.com/v2/categories/{category}/{subcategory}/{...}
    }
    */
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    shouldButtonBeActive: function () {
      return this.markets.filter((el) => this.isActiveButton(el)).length === 0;
    },

    myOffsetVal() {
      return this.offsetVal.valueOf();
    },

    endPointCompute() {
      return `https://api.widencollective.com/v2/assets/search?query=ff%3A({${this.fileType}})%20cat%3AMarket%2F${this.search}&limit=99&offset=${this.offsetVal}&expand=asset_properties%2Cfile_properties%2Cembeds%2Cthumbnails`;
    },

    cart() {
      return this.$store.state.cart;
    },

    currentMarket() {
      return this.$store.state.market;
    },

    markets() {
      return this.$store.state.markets;
    },

    fileTypes() {
      return this.$store.state.fileTypes;
    },

    products() {
      return this.$store.state.products;
    },
  },
  created() {
    this.$store.commit("showNavigation");
    /*
    if (this.$route.params.slug) {
      history.pushState({}, '', `/pagepath/path?query=${this.myQueryParam}`);
      this.this.pageSlug = this.$route.params.slug;
    }
    */
    // console.log(this.$route.path);
  },

  mounted() {
    this.firstLoad();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

.docwrap {
  background-repeat: no-repeat;
  background-position: center center;
}
.docwrap img {
  width: 100%;
  border-bottom: 1px solid #ddd;
}
.card {
  margin-bottom: 15px;
}
.word-wrap {
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.downloadImage a {
  color: #fff;
  transition: color 0.5s ease;
}
.downloadImage a:hover {
  color: #42b983;
}
.mr5 {
  margin-right: 5px;
}
.img-responsive-4by3 {
  display: block;
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
}
.img-responsive-4by3 img {
  width: 100%;
}
.img-responsive-16by9 {
  display: block;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}
.img-responsive-16by9 img {
  width: 100%;
}
span.file-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 5px;
  line-height: 1;
  border-radius: 4px;
}
.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 40px 40px 0;
  border-color: transparent #000000 transparent transparent;
  line-height: 0px;
  _border-color: #ffffff #000000 #ffffff #ffffff;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
}
.flexwrap {
  margin-bottom: 0;
}
</style>
